<template>
  <div>
    <LiefengModal
        title="新增功能"
        :value="controlForm.formFlag"
        :fullscreen="false"
        :width="600"
        :height="600"
        @input="closeModal"
    >
      <template v-slot:contentarea>
        <Form
            :model="formData"
            :label-colon="true"
            :label-width="100"
            :rules="formDataValidate"
            ref="form"
        >

          <FormItem label="功能名称" prop="functionName">
            <Input style="width:300px" v-model.trim="formData.functionName" :maxlength="50"
                   placeholder="请填写功能名称"></Input>
          </FormItem>
          <FormItem label="功能编码" prop="functionCode">
            <Input style="width:300px" v-model.trim="formData.functionCode" :maxlength="50"
                   placeholder="请填写功能编码"></Input>
          </FormItem>
          <FormItem label="父功能" prop="parentId">
            <Cascader
                style="margin-right: 10px;width:50%"
                v-model="formData.parentId"
                :data="controlForm.fatherFunctionList"
                change-on-select
            />
          </FormItem>
          <FormItem label="PC端路径" prop="urlPc">
            <Input style="width:300px" v-model.trim="formData.urlPc" :maxlength="50"
                   placeholder="请填写PC端路径"></Input>
          </FormItem>
          <FormItem label="H5路径" prop="urlH5">
            <Input style="width:300px" v-model.trim="formData.urlH5" :maxlength="50"
                   placeholder="请填写H5路径"></Input>
          </FormItem>
          <FormItem label="微信路径" prop="urlWechat">
            <Input style="width:300px" v-model.trim="formData.urlWechat" :maxlength="50"
                   placeholder="请填写微信路径"></Input>
          </FormItem>
          <FormItem label="配置参数JSON">
            <Input type="textarea" rows="5" v-model.trim="formData.configJson" style="width:300px" placeholder="请填写配置参数JSON"></Input>
          </FormItem>

        </Form>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="closeModal(false)">取消</Button>
        <Button type="primary" @click="saveData">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";

export default {
  components: {LiefengModal},
  data() {
    return {
      controlForm: {
        formFlag: false,
        timer:'',
        fatherFunctionList: [],
      },
      formData: {
        enableStatus:'1',
        parentId: [],
        functionName: '',
        functionCode: '',
        urlPc: '',
        urlH5: '',
        urlWechat: '',
        configJson: '',
      },
      formDataValidate: {
        functionName: [
          {required: true, message: '功能名称不能为空', trigger: 'blur'}
        ],
        functionCode: [
          {required: true, message: '功能编码不能为空', trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    changeEndTime(time){
      this.formData.endTime = time;
    },
    saveData() {
      this.$refs.form.validate((valid)=> {
            if (valid) {
              //校验json
              var configJson = this.formData.configJson
              if (configJson && typeof configJson == 'string') {
                try {
                  var obj = JSON.parse(configJson);
                  if (typeof obj != 'object' || !obj) {
                    this.$Message["error"]({
                      background: true,
                      content: "JSON格式不正确！"
                    });
                    return
                  }
                } catch (e) {
                  this.$Message["error"]({
                    background: true,
                    content: "JSON格式不正确！"
                  });
                  return
                }
              }

              if (this.formData.parentId && this.formData.parentId.length) {
                this.formData.parentId = this.formData.parentId[this.formData.parentId.length - 1];
              } else {
                //0就是功能最上层
                this.formData.parentId = 0;
              }

              this.$post('/auth/api/auth/pc/systemFunction/add',{
                ...this.formData,
              }).then(res=>{
                if(res.code == 200){
                  //functionCode校验 1成功2与同一级功能编码重复3与父级功能编码重复

                  if (res.data == 2) {
                    this.$Message.error({
                      content:'新增失败，与同一级功能编码重复',
                      background:true
                    })
                    return;
                  }

                  if (res.data == 3) {
                    this.$Message.error({
                      content:'新增失败，与父级功能编码重复',
                      background:true
                    })
                    return;
                  }

                  //刷新父功能
                  this.$emit('getFatherFunctionList');
                  this.$Message.success({
                    content:'新增成功',
                    background:true
                  });
                  //父页面刷新列表
                  this.$emit('resetBtn')
                  this.$emit('getAllFunction')
                  this.closeModal();
                }else{
                  this.$Message.error({
                    content:res.desc,
                    background:true
                  })
                  return
                }
              })
            }
          }
      );
    },
    closeModal(flag){
      //关闭弹窗(打开也会调用此方法)
      //清空表单
      Object.assign(this.$data.formData, this.$options.data().formData);
      //重置表单字段Form
      this.$refs['form'].resetFields();
      this.controlForm.formFlag = flag;

    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ #modal_contentarea{
  overflow: inherit !important;
}
</style>